<template>
  <div class="articles-table">
    <vx-card>
      <vue-bootstrap4-table :rows="data" :columns="getColumns" :config="config" :actions="actions"
                            @on-change-per-page="onChangePerPage"
                            @on-change-query="onChangeQuery" :total-rows="total_rows" columnSelection>
        <template slot="row_actions" slot-scope="props">
          <div class="column-actions-group">
            <vx-tooltip text="Bearbeiten" position="left">
              <vs-button color="warning" type="filled" icon="edit" size="small"
                         :to="'/b2b-sellers/demoshop/'+props.row.id"></vs-button>
            </vx-tooltip>
            <vx-tooltip text="Löschen" position="left">
              <vs-button color="primary"
                         type="filled"
                         icon="delete_forever"
                         size="small"
                         @click="openDeleteConfirm(props.row.id)"></vs-button>
            </vx-tooltip>
          </div>
        </template>
        <template slot="empty-results">
          Keine Demoshops gefunden.
        </template>
        <template slot="active" slot-scope="props" class="column-active">
          <div class="vbt-table-td">
            <div class="pl-2">
              <vs-row>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                  <vx-tooltip text="Inaktiv">
                    <span class="mega--circle red" v-if="props.row.active == '0'"></span>
                  </vx-tooltip>
                  <vx-tooltip text="Aktiv">
                    <span class="mega--circle green" v-if="props.row.active == '1'"></span>
                  </vx-tooltip>
                </vs-col>
              </vs-row>
            </div>
          </div>
        </template>
          <template slot="auto_reminder" slot-scope="props" class="column-active">
          <div class="vbt-table-td">
            <div class="pl-2">
              <vs-row>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                  <vx-tooltip text="Inaktiv">
                    <span class="mega--circle red" v-if="props.row.auto_reminder == '0'"></span>
                  </vx-tooltip>
                  <vx-tooltip text="Aktiv">
                    <span class="mega--circle green" v-if="props.row.auto_reminder == '1'"></span>
                  </vx-tooltip>
                </vs-col>
              </vs-row>
            </div>
          </div>
        </template>
        <template slot="is_internal" slot-scope="props" class="column-active">
          <div class="vbt-table-td">
            <div class="pl-2">
              <vs-row>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                  <vx-tooltip text="Nicht intern">
                    <span class="mega--circle red" v-if="props.row.is_internal == '0'"></span>
                  </vx-tooltip>
                  <vx-tooltip text="Interner Demoshop">
                    <span class="mega--circle green" v-if="props.row.is_internal == '1'"></span>
                  </vx-tooltip>
                </vs-col>
              </vs-row>
            </div>
          </div>
        </template>
          <template slot="crm_deal" slot-scope="props" class="column-active">
          <div class="vbt-table-td">
            <div class="pl-2">
              <vs-row>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                  <vx-tooltip text="Kein Deal vorhanden">
                    <span class="mega--circle red" v-if="props.row.crm_deal_id == null"></span>

                  </vx-tooltip>
                    <div class="text-center">
                        <vx-tooltip text="Deal vorhanden">
                            <span class="mega--circle green text-center" v-if="props.row.crm_deal_id"></span>
                            <a :href="'https://b2bsellersgmbh.pipedrive.com/deal/'+ props.row.crm_deal_id" target="_blank" v-if="props.row.crm_deal_id" class="w-full d-block crm-deal-link">CRM-Deal öffnen</a>
                        </vx-tooltip>
                    </div>

                </vs-col>
              </vs-row>
            </div>
          </div>
        </template>
        <template slot="email" slot-scope="props" class="column">
          <div class="email-column" style="overflow: scroll;
    max-width: 410px;
        overflow-x: scroll;">
            {{props.row.email}}
          </div>
        </template>

      </vue-bootstrap4-table>
    </vx-card>
  </div>
</template>

<script>
import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'

import ApiService from "../../../api";

import moment from 'moment';
import QueryHelper from "../../../mixins/helper/query.helper";

import findIndex from "lodash/findIndex";
import PriceHelper from "../../../mixins/helper/price.helper";

var qs = require('qs');

import vSelect from 'vue-select'

export default {
  components: {
    VueBootstrap4Table,
    'v-select': vSelect
  },
  data() {
    return {
      data: [],
      actions: [
        {
          btn_text: "Hinzufügen",
          router_link: '/b2b-sellers/demoshop/add',
          color: 'success'
        }
      ],
      columns: [
        {
          label: "Aktiv",
          name: "active",
          slot_name: "active",
          filter: {
            type: "simple",
            placeholder: "1 oder 0"
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
        },
        {
            label: "Auto Reminder",
            name: "auto_reminder",
            sort: true,
            slot_name: "auto_reminder",
            filter: {
                type: "simple",
                placeholder: "1 oder 0"
            },
            row_text_alignment: 'text-left',
            column_text_alignment: 'text-left',
            column_classes: 'active-column',
            showCol: true,
        },
        {
          label: "Intern",
          name: "is_internal",
          slot_name: "is_internal",
          filter: {
            type: "simple",
            placeholder: "1 oder 0"
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
        },
        {
          label: "Domain",
          name: "domain",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
        },
        {
          label: "Name",
          name: "full_name",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'name-column',
          showCol: true,
        },
        {
          label: "E-Mail",
          name: "email",
          slot_name: "email",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
          width:'7%',
        },
          {
              label: "CRM-Deal",
              name: "crm_deal_id",
              slot_name: "crm_deal",
              filter: {
                  type: "simple",
                  placeholder: "Suchen..."
              },
              sort: true,
              row_text_alignment: 'text-left',
              column_text_alignment: 'text-left',
              showCol: true,
              width:'7%',
          },
        {
          label: "Sprache",
          name: "language_code",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
        },
          {
              label: "Demo-Termin?",
              name: "personal_demo_date",
              filter: {
                  type: 'date',
                  mode: 'range'
              },
              formatter: this.formatDate,
              sort: true,
              row_text_alignment: 'text-left',
              column_text_alignment: 'text-left',
              showCol: true,
          },
          {
              label: "Valid to",
              name: "valid_to",
              filter: {
                  type: 'date',
                  mode: 'range'
              },
              formatter: this.formatDate,
              sort: true,
              row_text_alignment: 'text-left',
              column_text_alignment: 'text-left',
              showCol: true,
          },

        {
          label: "Erstellt",
          name: "created_at",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          formatter: this.formatDateTime,
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
        },

        {
          label: "Aktionen",
          slot_name: "row_actions",
          sort: false,
          row_text_alignment: 'text-right',
          column_text_alignment: 'text-right',
          column_classes: 'actions-column',
          showCol: true,
        }],
      config: {
        checkbox_rows: false,
        show_refresh_button: false,
        show_reset_button: false,
        highlight_row_hover_color: '#f8f8f8',
        server_mode: true,
        per_page: 10
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1,
      },
      total_rows: 0,
      selectedForDialog: null,
      categories: [],
      selectedCategories: []
    }
  },
  computed: {
    requestParams() {
      return QueryHelper.parseRequestParams(this.queryParams);

    },
    getColumns() {
      return this.columns;
    }
  },
  methods: {
    fetchData() {
      this.$vs.loading();

      let demoshopPromise = ApiService.get('b2b-sellers/demoshop', {
        params: this.requestParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      });

      Promise.all([demoshopPromise])
          .then(([demoshop]) => {
            this.data = demoshop.data.result;
            this.total_rows = demoshop.data.total;
            this.$vs.loading.close();
          });
    },

    formatDateTime(value) {
      if (!value){
        return "-"
      }
      return moment(new Date(value)).format('DD.MM.YYYY - H:m')
    },
    formatDate(value) {
      if (!value){
        return "-"
      }
      return moment(new Date(value)).format('DD.MM.YYYY')
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.fetchData();
    },
    onChangePerPage(value) {
      this.config.per_page = value;
    },
    openDeleteConfirm(id) {
      this.selectedForDialog = id;

      this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: 'Bestätigen',
        text: 'Sind Sie sich sicher dass dieser Artikel gelöscht werden soll?',
        accept: this.acceptDelete,
        acceptText: 'Löschen',
        cancelText: 'Abbrechen'
      })
    },

    acceptDelete() {
      ApiService.delete('b2b-sellers/demoshop/' + this.selectedForDialog).then((response) => {

        this.fetchData();

        if (response.data.status === 'success') {
          return this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Wurde erfolgreich gelöscht',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
        }

        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Konnte nicht gelöscht werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }).catch((error) => {
        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Konnte nicht gelöscht werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
  },
}

</script>

<style lang="scss">
.articles-table {
  .ordernumner-column {
    width: 10%;
  }

  .type-column {
    width: 15%;
  }

  /*
  .desc-column {
      width: 20%;
  }
  .name-column {
      width: 20%;
  }
  .price-column {
      width: 7.5%;
  }
  .date-column {
      width: 15%;
  }
  */
  .actions-column {
    width: 8%;
  }

  .active-column {
    width: 6%;
  }
}
.crm-deal-link{
  font-size: 9px;
  text-align: center;
  color: forestgreen!important;
  line-height: normal;
}
</style>
